import React from 'react'

export const B3350 = ({ ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="1421"
    height="808"
    viewBox="0 0 1421 808"
    {...props}
  >
    <defs>
      <pattern id="pattern" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 173 74">
        <image width="173" height="74" href="../b3-350/B3-350-PA01.321.13E.1701_0022_Camada-25.png" />
      </pattern>
      <pattern id="pattern-2" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 46 31">
        <image width="46" height="31" href="../b3-350/B3-350-PA01.321.13E.1701_0018_Camada-21.png" />
      </pattern>
      <pattern id="pattern-3" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 75 147">
        <image width="75" height="147" href="../b3-350/B3-350-PA01.321.13E.1701_0015_Camada-18.png" />
      </pattern>
      <pattern id="pattern-4" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 95 111">
        <image width="95" height="111" href="../b3-350/B3-350-PA01.321.13E.1701_0013_Camada-16.png" />
      </pattern>
      <pattern id="pattern-5" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 23 27">
        <image width="23" height="27" href="../b3-350/B3-350-PA01.321.13E.1701_0009_Camada-12.png" />
      </pattern>
      <pattern id="pattern-6" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 32 36">
        <image width="32" height="36" href="../b3-350/B3-350-PA01.321.13E.1701_0007_Camada-10.png" />
      </pattern>
      <pattern id="pattern-7" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 90 109">
        <image width="90" height="109" href="../b3-350/B3-350-PA01.321.13E.1701_0005_Camada-8.png" />
      </pattern>
      <pattern id="pattern-8" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 28 31">
        <image width="28" height="31" href="../b3-350/B3-350-PA01.321.13E.1701_0006_Camada-9.png" />
      </pattern>
      <pattern id="pattern-9" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 78 107">
        <image width="78" height="107" href="../b3-350/B3-350-PA01.321.13E.1701_0003_Camada-6.png" />
      </pattern>
      <pattern id="pattern-10" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 126 157">
        <image width="126" height="157" href="../b3-350/B3-350-PA01.321.13E.1701_0002_Camada-5.png" />
      </pattern>
    </defs>
    <g
      id="Grupo_90"
      data-name="Grupo 90"
      transform="translate(-240 -116)"
      className="vista-g"
    >
      <rect id="B3-350-PA01.321.13E.1701_0022_Camada-25" width="172" height="74" transform="translate(1393 116)" fill="url(#pattern)" />
      <image id="B3-350-PA01.321.13E.1701_0020_Camada-23" width="171" height="266" transform="translate(1394 216)" href="../b3-350/B3-350-PA01.321.13E.1701_0020_Camada-23.png" />
      <image id="B3-350-PA01.321.13E.1701_0021_Camada-24" width="15" height="32" transform="translate(1611 200)" href="../b3-350/B3-350-PA01.321.13E.1701_0021_Camada-24.png" />
      <image id="B3-350-PA01.321.13E.1701_0019_Camada-22" width="106" height="161" transform="translate(1266 297)" href="../b3-350/B3-350-PA01.321.13E.1701_0019_Camada-22.png" />
      <rect id="B3-350-PA01.321.13E.1701_0018_Camada-21" width="45" height="31" transform="translate(1616 665)" fill="url(#pattern-2)"
        data-name="Condensador 2HP"
        data-url="https://shopirbal.com/pt/produto/condensador-2hp/" />
      <image id="B3-350-PA01.321.13E.1701_0016_Camada-19" width="96" height="90" transform="translate(1518 505)" href="../b3-350/B3-350-PA01.321.13E.1701_0016_Camada-19.png"
        data-name="Motor ELE 2,00HP"
        data-url="https://shopirbal.com/pt/produto/motor-ele-2-00hp/" />
      <rect id="B3-350-PA01.321.13E.1701_0015_Camada-18" width="74" height="146" transform="translate(1372 519)" fill="url(#pattern-3)"
        data-name="Correia PV 10PJ 1355"
        data-url="https://shopirbal.com/pt/produto/correia-pv-10pj-1355/" />
      <image id="B3-350-PA01.321.13E.1701_0017_Camada-20" width="39" height="49" transform="translate(1513 741)" href="../b3-350/B3-350-PA01.321.13E.1701_0017_Camada-20.png"
        data-name="Interruptor B3+B4"
        data-url="https://shopirbal.com/pt/produto/interruptor-b3b4/" />
      <rect id="B3-350-PA01.321.13E.1701_0013_Camada-16" width="94" height="110" transform="translate(1278 790)" fill="url(#pattern-4)"
        data-name="Roda Maciça Ø400"
        data-url="https://shopirbal.com/pt/produto/roda-macica-400/" />
      <image id="B3-350-PA01.321.13E.1701_0014_Camada-17" width="136" height="112" transform="translate(1265 665)" href="../b3-350/B3-350-PA01.321.13E.1701_0014_Camada-17.png" />
      <image id="B3-350-PA01.321.13E.1701_0012_Camada-15" width="86" height="97" transform="translate(1206 453)" href="../b3-350/B3-350-PA01.321.13E.1701_0012_Camada-15.png" />
      <image id="B3-350-PA01.321.13E.1701_0001_Camada-4" width="256" height="247" transform="translate(559 196)" href="../b3-350/B3-350-PA01.321.13E.1701_0001_Camada-4.png" />
      <image id="B3-350-PA01.321.13E.1701_0010_Camada-13" width="420" height="499" transform="translate(782 425)" href="../b3-350/B3-350-PA01.321.13E.1701_0010_Camada-13.png" />
      <image id="B3-350-PA01.321.13E.1701_0011_Camada-14" width="68" height="48" transform="translate(1094 363)" href="../b3-350/B3-350-PA01.321.13E.1701_0011_Camada-14.png"
        data-name="Rolamento do Lado do Pinhão RL 6205 2RS"
        data-url="https://shopirbal.com/pt/produto/rolamento-rl-6205-2rs/" />
      <rect id="B3-350-PA01.321.13E.1701_0009_Camada-12" width="24" height="28" transform="translate(954 522)" fill="url(#pattern-5)" />
      <rect id="B3-350-PA01.321.13E.1701_0007_Camada-10" width="31" height="35" transform="translate(904 637)" fill="url(#pattern-6)"
        data-name="Pinhão/Carreto B3 + CAVILHA"
        data-url="https://shopirbal.com/pt/produto/pinhao-carreto-b3-cavilha/" />
      <rect id="B3-350-PA01.321.13E.1701_0005_Camada-8" width="89" height="109" transform="translate(657 668)" fill="url(#pattern-7)" />
      <image id="B3-350-PA01.321.13E.1701_0008_Camada-11" width="123" height="66" transform="translate(798 468)" href="../b3-350/B3-350-PA01.321.13E.1701_0008_Camada-11.png"
        data-name="Rolamento do Cubo do Balde RL 32011 X"
        data-url="https://shopirbal.com/pt/produto/rolamento-do-cubo-do-balde-rl-32011-x/" />
      <rect id="B3-350-PA01.321.13E.1701_0006_Camada-9" width="27" height="30" transform="translate(753 609)" fill="url(#pattern-8)" />
      <image id="B3-350-PA01.321.13E.1701_0004_Camada-7" width="35" height="26" transform="translate(609 811)" href="../b3-350/B3-350-PA01.321.13E.1701_0004_Camada-7.png" />
      <rect id="B3-350-PA01.321.13E.1701_0003_Camada-6" width="78" height="108" transform="translate(485 741)" fill="url(#pattern-9)" />
      <rect id="B3-350-PA01.321.13E.1701_0002_Camada-5" width="125" height="157" transform="translate(304 765)" fill="url(#pattern-10)" />
      <image id="B3-350-PA01.321.13E.1701_0000_Camada-3" width="220" height="264" transform="translate(240 352)" href="../b3-350/B3-350-PA01.321.13E.1701_0000_Camada-3.png"
        data-name="Cremalheira de Ferro Fundido 350-400-410"
        data-url="https://shopirbal.com/pt/produto/cremalheira-de-ferro-fundido-350-400-410/" />

    </g>
  </svg>
)
